import React,{useEffect,useCallback,useRef} from 'react';
import styled from 'styled-components';

import instagramIcon from './assets/insta.svg';

const InstagramIcon = styled(({className})=>(
	<div className={className}>
		<img alt="instagram.com" src={instagramIcon}/>
		&nbsp;&nbsp;&nbsp;
	</div>
))`
	display: inline;
	position: relative;
	margin-left: 0.1em;
	& > img{
		position: absolute;
		top: 0em;
		right: 0.05em;
		max-width: none;
		height: 0.8em;
		width: 0.8em;
	}
`;

const mobile = '@media screen and (orientation:portrait)';

const Bold = styled.span`
	font-weight: bold;
	text-transform: uppercase;
`;
const Arrow = styled.span`
	position: relative;
	top: -0.1em;
	margin-right: 0.1em;
`;
const Bullet = styled.span`
	position: relative;
	top: -0.1em;
	/* margin-right: 0.3em; */
`;
const At = styled.span`
`;

const Content = styled(({className})=>(
	<div className={className}>
		<Bold>
			&nbsp;Booking<Arrow>→</Arrow>
			<a href="mailto:info@ravenart.pt">
			 	<span>info<At>@</At>ravenart.pt</span>
			</a>
			&nbsp;<Bullet>•</Bullet>&nbsp;
		</Bold>
		<a href="https://instagram.com/RavenArt" target="_blank" rel="noopener noreferrer">
				<span><InstagramIcon/>/RavenArt</span>
		</a>
		&nbsp;
		<Bullet>•</Bullet>
	</div>
))`
	display: inline-block;
	user-select: none;
	position: relative;
	top: 0em;
	& span{
		display: inline-block;
	}
	& a{
		transition-duration: 0.3s;
		transition-property: border-bottom;
		border-bottom: 0.12em solid transparent;

		position: relative;
		bottom: .1em;
		& > span{
			position: relative;
			bottom: -.09em;
		}

	}
	& a:hover{
		border-bottom: 0.12em solid black;
	}
`;

function easeInQuint(x){
	return x*x*x*x*x;
};
function easeOutQuint(x){
	return 1-Math.pow(1-x,5);
};

const _wrapper = ({className,children})=>{

	const refStartTime = useRef(-1);
	const refLastTime = useRef(-1);
	const refStoppingTime = useRef(-1);
	const refRestartingTime = useRef(-1);
	const div = useRef();

	const generateFrame = useCallback(s => {
		return `transform: translateX(${-s*50}%)`;
	},[]);

	const duration = 8000;
	const stopDuration = 1000;

	const animate = useCallback((time)=>{

		if(refStartTime.current === -1)
			refLastTime.current = refStartTime.current = time;

		// var hover = false;
		// const links = div.current.parentElement.getElementsByTagName('a');
		// for(const link of links)
		// 	if(link.matches(':hover')){
		// 		hover = true;
		// 		break;
		// 	}

		var speed = 1;
		// if(hover){
		if(div.current.parentElement.matches(':hover')){
			refRestartingTime.current = -1;
			if(refStoppingTime.current === -1)
				refStoppingTime.current = time;
			const stopPosition = (time - refStoppingTime.current) / stopDuration;
			speed = easeInQuint(Math.max(0,1-stopPosition));
		}else{
			refStoppingTime.current = -1;
			if(refRestartingTime.current === -1)
				refRestartingTime.current = time;
			const restartPosition = (time - refRestartingTime.current) / stopDuration;
			speed = easeOutQuint(Math.min(1,restartPosition));
		}

		refStartTime.current += (time - refLastTime.current)*(1-speed);

		const position = (time - refStartTime.current) / duration;


		div.current.style = generateFrame(position);

		if(position >= 1)
			refStartTime.current = -1;

		refLastTime.current = time;

		window.requestAnimationFrame(animate);
	},[refStartTime,refLastTime,refStoppingTime,refRestartingTime,generateFrame]);

	useEffect(()=>{
		setTimeout(()=>{
			window.requestAnimationFrame(animate);
		},1000);
	},[animate]);

	return <div className={className} ref={div}>{children}</div>;
};

const Wrapper = styled(_wrapper)`
	position: absolute;
	left: 0;
`;

const Root = styled.div`
	background-color: #FF001A;
	transform-origin: 0% 100%;
	position: absolute;
	white-space: nowrap;
	overflow: hidden;

	transform: rotate(90deg);
	height: ${118/1024*100}vh;
	width: 100vh;
	line-height: ${118/1024*100}vh;
	font-size: ${66.5/1024*100}vh;
	left: calc(100vw - ${118/1024*100}vh);
	top: ${-118/1024*100}vh;
	${mobile}{
		transform: none;
		height: ${69/414*100}vw;
		width: 100vw;
		line-height: ${69/414*100}vw;
		font-size: ${37/414*100}vw;
		left: 0;
		top: unset;
		bottom: 0;
	}
`;

export default function ScrollingBanner({className}){
	return (
		<Root className={className}>
			<Wrapper>
				<Content/>
				<Content/>
			</Wrapper>
		</Root>
	);
};
