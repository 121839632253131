import React from 'react';
import { hot } from 'react-hot-loader/root'
import Home from 'Home';

function App() {
  return (
    <Home/> 
  );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App
