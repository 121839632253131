import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'reset.css';
import 'fonts';
// import { Provider as ReduxProvider } from 'react-redux';
// import { createStore } from 'redux';
// import { reducer } from 'declarative-redux';
// const store = createStore(reducer);

  // <ReduxProvider store={store}>
  //   <App />
  // </ReduxProvider>

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();
